import styled from 'styled-components'

const Content = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 8px;

  > p,
  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    width: 100%;
    max-width: 680px;
    align-self: center;
  }

  > p:not(:first-child),
  > div:not(:first-child) {
    margin: 24px 0 0;
  }

  > h1,
  > h2,
  > h3,
  > h4,
  > h5,
  > h6 {
    margin: 80px 0 24px;
  }
`

export default Content
