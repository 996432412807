import SEO from 'components/SEO'
import Content from 'components/Content'

export default function NotFoundPage() {
  return (
    <>
      <SEO title="404: Not found" />

      <Content>
        <h1 css="text-align: center; margin-top: 100px;">¯\_(ツ)_/¯</h1>
      </Content>
    </>
  )
}
